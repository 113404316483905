import * as React from 'react'

interface UserSettingsViewContextType {
  userSettings: {
    menuCollapse: boolean
    theme: string
    funnelStepCount: number
  }
  setUserSettings: React.Dispatch<
    React.SetStateAction<UserSettingsViewContextType['userSettings']>
  >
}

const UserSettingsViewContext =
  React.createContext<UserSettingsViewContextType | null>(null)

interface TabViewProviderProps {
  children: React.ReactNode
}

export const UserSettingsViewProvider: React.FC<TabViewProviderProps> =
  React.memo(({ children }) => {
    const getInitialState = () => {
      const storedState = localStorage.getItem('pruuvAppState')
      return storedState
        ? JSON.parse(storedState)
        : { menuCollapse: false, theme: 'light', funnelStepCount: 1 }
    }

    const [userSettings, setUserSettings] = React.useState(getInitialState)

    // Persist state to localStorage when it changes
    React.useEffect(() => {
      localStorage.setItem('pruuvAppState', JSON.stringify(userSettings))
    }, [userSettings])

    return (
      <UserSettingsViewContext.Provider
        value={{ userSettings, setUserSettings }}
      >
        {children || <div>No children passed to UserSettingsViewProvider!</div>}
      </UserSettingsViewContext.Provider>
    )
  })

export const useUserSettingsViewContext = () => {
  const context = React.useContext(UserSettingsViewContext)

  if (!context) {
    throw new Error(
      'useUserSettingsViewContext must be used within a UserSettingsViewProvider',
    )
  }
  return context
}
